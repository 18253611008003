<script lang="ts" setup>
withDefaults(
  defineProps<{
    date: string | number
    isActive?: boolean
    isDayOfWeek?: boolean
    isDisabled?: boolean
  }>(),
  { isActive: false, isDayOfWeek: false, isDisabled: false }
)

const emit = defineEmits<{
  (e: 'selected'): void
}>()
</script>

<template>
  <a
    class="
      bg-white font-regular py-2.5 text-dark text-center text-sm rounded-lg w-full
      border border-solid border-transparent
      !no-underline
    "
    href="#"
    :class="{ '!border-green': isActive, '!text-[#D1D1D1] cursor-default pointer-events-none': isDisabled }"
    @click.prevent="emit('selected')"
  >
    <template v-if="isDayOfWeek">
      {{ date }}
    </template>
    <template v-else>
      {{ (new Date(date)).getDate() }}
    </template>
  </a>
</template>
